.rs-current-lot {
    align-items: center;
    background: #fff;
    display: grid;
    grid-template-columns: 3fr 2fr;
    align-items: center;
    font-size: 55px;
    gap: 0;
    padding: 16px;

    .rs-current-lot__left {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        text-align: center;
        width: 90%;

        .text-primary {
            font-size: 50px;
            font-weight: bold;
        }

        .rs-current-lot-item {
            display: grid;
            gap: 0;
            grid-template-columns: 35% 80%;
            font-weight: bold;
            text-align: left;
            align-items: center;

            span:first-child {
                font-size: 40px;
                opacity: 0.7;
                border-bottom: solid 1px #d9d9d9;
                height: 80px;
                padding: 4px;
            }

            span:last-child {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding: 4px 8px;
                max-width: 600px;
                border-bottom: solid 1px #d9d9d9;
                height: 80px;
            }
        }

        .rs-current-lot-item:last-child {
            margin-top: 16px;
        }

        .rs-current-lot-item span:last-child {
            text-align: right;
        }

        .auctioneer-screen__ll-item:last-child {
            margin-top: 0;
        }
    }

    .rs-current-lot__right {
        text-align: right;
    }

    .rs-current-lot__price {
        display: grid;
        gap: 16px;
        box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
        height: calc(100vh);
        max-height: 540px;
        padding: 20px;
        float: right;
        text-align: center;
        font-weight: bold;
        width: 90%;

        .rs-current-lot__number {
            font-size: 60px;
        }

        .rs-current-lot__bidder {
            font-size: 40px;
        }
    }

    .rs-current-lot__no-bids {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 60px;
        opacity: 0.4;
    }

    .bid-colour-red {
        background-color: rgba($saleyard-warning, 0.5) !important;
        border: solid 2px rgba($saleyard-warning, 0.5);
    }

    .bid-colour-green {
        background-color: rgba($saleyard-green, 0.5) !important;
        border: solid 2px rgba($saleyard-green, 0.5);
    }
}

.rostrum-screen {
    .rs-current-lot {
        .bid-colour-red {
            background-color: rgba($saleyard-warning, 0.5) !important;
            border: solid 2px rgba($saleyard-warning, 0.5);
        }

        .bid-colour-green {
            background-color: rgba($saleyard-green, 0.5) !important;
            border: solid 2px rgba($saleyard-green, 0.5);
        }
    }
}
